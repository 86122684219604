/* eslint-disable */
import React, { Component } from 'react';
import VideoPlay from "../VideoPlay";
import close from "../../assets/images/x.svg";
class ModalVideo extends Component  {
    videosrc = ""
    constructor(props) {
      super(props);
      console.log(props)
      this.closeVideoPlay = this.props.closeVideoPlay.bind(this);

    
    }


    getVideo(videos,lenguage){
      return videos[lenguage]
        
    }
    render() {
      
         
        
        return  <div
        className="modal fade"
        id="videoPlay"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body paddingImage videoModelbody">
              <div
                style={{
                  width: 40,
                  height: 40,
                  padding: 2,
                  right: 3,
                  position: "absolute",
                  background: "white",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "inline-flex",
                  cursor: "pointer",
                }}
                onClick={() => this.closeVideoPlay()}
              >
                <div
                  style={{
                    width: 36,
                    height: 36,
                    position: "relative",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    display: "flex",
                  }}
                >
                  <img src={close} style={{ width: 36, height: 36 }} />
                </div>
              </div>
              <VideoPlay src={this.getVideo(this.props.videos,this.props.language)}/>
            </div>
          </div>
        </div>
      </div>
    }
}

export default ModalVideo