
/* eslint-disable */
import { useState, useEffect } from "react";

import oil from "../assets/images/oil-and-gas.gif";
import oil_ from "../assets/images/oilpng.png";
import power_ from "../assets/images/Power_Utilities_png.png";
import mining_ from "../assets/images/mining_png.png";
import transportation_ from "../assets/images/Transportation&Logistics_png.png";
import manufacture_ from "../assets/images/manufacturing_png.png";
import other_ from "../assets/images/Other_png.png";
import oilselect from "../assets/images/oil-and-gas.gif";
import power from "../assets/images/Power & Utilities.gif";
import powerselect from "../assets/images/Power & Utilities.gif";
import mining from "../assets/images/mining.gif";
import miningselect from "../assets/images/mining.gif";
import transportation from "../assets/images/Transportation&Logistics.gif";
import transportationselect from "../assets/images/Transportation&Logistics.gif";
import other from "../assets/images/Other.gif";
import otherselect from "../assets/images/Other.gif";
import manufacture from "../assets/images/manufacturing.gif";
import manufactureselect from "../assets/images/manufacturing.gif";
import { useTranslation } from "react-i18next";
import { BsBorderCenter } from "react-icons/bs";
export default function Question0(props) {
  const [index, setIndex] = useState(
    props.questionList[0] ? props.questionList[0] : 0
  );
  const { t, i18n } = useTranslation();
  const [overText, setOverText] = useState(0);
  const [showAnimation, setShowAnimation] = useState(true);
  // const selectedIndustry = props.questionList[0];
  // if (selectedIndustry) {
  //   setIndex(selectedIndustry);
  // }

  useEffect(() => {
    setTimeout(() => {
      setShowAnimation(false);
    }, 2000);
  });
  const onClickSelect = (number, name) => {
    setIndex(number);
    props.setIndustry(name);
    if (name != "Other") {
      let tempQuestion = [...props.questionList];
      tempQuestion[0] = number;
      props.setQuestionList(tempQuestion);
      props.nextQuestion();
    }
  };
  const mouseEnter = (indec) => {
    setOverText(indec);
    if (!indec) {
      setOverText(0);
    } else {
      setOverText(indec);
    }
  };
  return (
   
      <div className="row">
        <div className="col-lg-4 col-6 ">
          <div
            className="col industryBox"
            style={{ background: index === 1 ? "#DA291C" : "white" }}
            id="gas"
            onMouseOver={(e) => mouseEnter(1)}
            onMouseLeave={(e) => mouseEnter(0)}
            onClick={(e) => onClickSelect(1, "Oil & Gas")}
          >
            <div
              style={{
                color: index === 1 ? "white" : "#0F0E0E",
              }}
              className="industryBoxText"
            >
              {t("indusrty_name1")}
            </div>
            <div className="industryBoxImage">
              {showAnimation && <img src={oil} className="industryImage" />}
              {!showAnimation && (
                <img
                  src={index === 1 ? oil : overText === 1 ? oil : oil_}
                  className="industryImage"
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-6 ">
          <div
            className="industryBox"
            style={{ background: index === 2 ? "#DA291C" : "white" }}
            id="power"
            onClick={(e) => onClickSelect(2, "Power & Utilities")}
            onMouseOver={(e) => mouseEnter(2)}
            onMouseLeave={(e) => mouseEnter(0)}
          >
            <div
              style={{
                color: index === 2 ? "white" : "#0F0E0E",
              }}
              className="industryBoxText"
            >
              {t("indusrty_name2")}
            </div>
            <div className="industryBoxImage">
              {showAnimation && <img src={power} className="industryImage" />}
              {!showAnimation && (
                <img
                  src={index === 2 ? power : overText === 2 ? power : power_}
                  className="industryImage"
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-6 ">
          <div
            className="industryBox"
            style={{ background: index === 3 ? "#DA291C" : "white" }}
            id="mining"
            onClick={(e) => onClickSelect(3, "Mining")}
            onMouseOver={(e) => mouseEnter(3)}
            onMouseLeave={(e) => mouseEnter(0)}
          >
            <div
              style={{
                color: index === 3 ? "white" : "#0F0E0E",
              }}
              className="industryBoxText"
            >
              {t("indusrty_name3")}
            </div>
            <div className="industryBoxImage">
              {showAnimation && <img src={mining} className="industryImage" />}
              {!showAnimation && (
                <img
                  src={index === 3 ? mining : overText === 3 ? mining : mining_}
                  className="industryImage"
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-6 ">
          <div
            className="industryBox"
            style={{ background: index === 4 ? "#DA291C" : "white" }}
            id="transportation"
            onClick={(e) => onClickSelect(4, "Transportations & Logistic")}
            onMouseOver={(e) => mouseEnter(4)}
            onMouseLeave={(e) => mouseEnter(0)}
          >
            <div
              style={{
                color: index === 4 ? "white" : "#0F0E0E",
              }}
              className="industryBoxText"
            >
              {t("indusrty_name4")}
            </div>
            <div className="industryBoxImage">
              {showAnimation && (
                <img src={transportation} className="industryImage" />
              )}
              {!showAnimation && (
                <img
                  src={
                    index === 4
                      ? transportation
                      : overText === 4
                      ? transportation
                      : transportation_
                  }
                  className="industryImage"
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-6 ">
          <div
            className="industryBox"
            style={{ background: index === 5 ? "#DA291C" : "white" }}
            id="manufacturing"
            onClick={(e) => onClickSelect(5, "Manufacturing")}
            onMouseOver={(e) => mouseEnter(5)}
            onMouseLeave={(e) => mouseEnter(0)}
          >
            <div
              style={{
                color: index === 5 ? "white" : "#0F0E0E",
              }}
              className="industryBoxText"
            >
              {t("indusrty_name5")}
            </div>
            <div className="industryBoxImage">
              {showAnimation && (
                <img src={manufacture} className="industryImage" />
              )}
              {!showAnimation && (
                <img
                  src={
                    index === 5
                      ? manufacture
                      : overText === 5
                      ? manufacture
                      : manufacture_
                  }
                  className="industryImage"
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-6 ">
          <div
            className="industryBox"
            style={{ background: index === 6 ? "white" : "white" }}
            id="other"
            onClick={(e) => onClickSelect(6, "Other")}
            onMouseOver={(e) => mouseEnter(6)}
            onMouseLeave={(e) => mouseEnter(0)}
          >
            <div
              // style={{
              //   color: index === 6 ? "white" : "#0F0E0E",
              // }}
              className="industryBoxText"
            >
              {t("indusrty_name6")}
            </div>
            <div className="industryBoxImage">
              {showAnimation && <img src={other} className="industryImage" />}
              {!showAnimation && (
                <img
                  src={index === 6 ? other : overText === 6 ? other : other_}
                  className="industryImage"
                />
              )}
            </div>
          </div>
        </div>
      </div>

  );
}
