import logo from "./Fortinet logo.svg";
import "./App.css";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    // <div className="fullpage_background_color">
    <div className="background-image">
      {/* <img src={bg} alt="" width="100%" /> */}
      <img
        src={logo}
        alt=""
        style={{
          width: "100%",
          height: "24px",
          textalign: "center",
          position: "absolute",
          // top="20%"
          marginTop: "15px",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "95.5vh",
          flexDirection: "column",
        }}
      >
        <h1 className="error_404">404 </h1>
        <h1 className="Page_notfound">Page Not Found</h1>
        {/* <button className="goToHomepage">Go to the Home Page</button> */}
        <button className="buttonHome" onClick={() => navigate("/")}>
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 8,
              display: "flex",
            }}
          >
            <div
              style={{
                fontSize: "16px",
                fontFamily: "Inter",
                fontWeight: "600",
                lineHeight: 1,
                wordWrap: "break-word",
              }}
            >
              Go to the Home Page
            </div>
            <BsArrowRight />
          </div>
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
