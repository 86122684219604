/* eslint-disable */
import React, { Component } from 'react';

// const VideoPlay = () => {
//   const videoRef = useRef();

//   const handlePlay = () => {
//     videoRef.current.play();
//   };

//   return (
//     <>
//      
//     </>
//   );
// };

class VideoPlay extends Component {
  
  render() {
    this.src = this.props.src
    return (
      <div className="grid-btn">
        <iframe
          width="100%"
          height="500"
          id="video_play"
          src = {this.src}
        //src=""
        //ref={videoRef}
        ></iframe>
      </div>
    )


  }
}

export default VideoPlay;
