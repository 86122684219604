/* eslint-disable */
import React, { Component , useState, useRef, useEffect} from 'react';
import modalImage from '../../assets/images/modal_3.png';
import playImg from "../../assets/images/play button.svg";
import { withTranslation  } from "react-i18next";
import close from "../../assets/images/x.svg";


class  ModalEmail extends Component{
   
    constructor(props){
        super(props)
        this.t = this.props.t
        //this.show = false
       // this.closeModalOut = this.props.closeModal.bind(this)
        this.state={
            companyEmail : "",
            isShow:'',
            display:'none',
            EnableConfirm:false
        }

    }


    showModal() {
      //this.show =  true
      console.log("Enter")
      this.setState({display:'block'})
      this.setState({isShow:'show'})
    };

    
  
    closeModal()
    {
     
        this.setState({display:'none'})
        this.setState({isShow:''})
        console.log("Enter");
      
     
    }

    

    onEmailChange = (e) => {
        //setCompanyEmail(e.target.value);
        this.setState({companyEmail:e.target.value})
        console.log("email test");
        if (
          e.target.value.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          //setEnableConfirm(true);
        } else {
          //setEnableConfirm(false);
        }
      };

    render(){
        
        return (
          <div
          className={`modal fade ${this.state.isShow}`}
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          style={{display:this.state.display}}
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
              <div className="modal-body paddingImage">
                <div className="container-fluid paddingImage">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 order-lg-last">
                      <div
                        style={{
                          width: "100%",
                          height: 450,
                          position: "relative",
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            background:
                              "linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%)",
                            borderTopRightRadius: 300,
                            objectFit: "cover",
                          }}
                          src={modalImage}
                        />
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            padding: 2,
                            right: 3,
                            position: "absolute",
                            background: "white",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "inline-flex",
                            cursor: "pointer",
                          }}
                          onClick={() => this.props.closeModal()}
                        >
                          <div
                            style={{
                              width: 36,
                              height: 36,
                              position: "relative",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                              display: "flex",
                            }}
                          >
                            <img src={close} style={{ width: 36, height: 36 }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 order-lg-first">
                      <div
                        style={{
                          width: "100%",
                          //height: 400,
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            // alignSelf: "stretch",
                            color: "#DA291C",
                            fontSize: 20,
                            fontFamily: "Inter",
                            fontWeight: "700",
                            textTransform: "uppercase",
                            wordWrap: "break-word",
                            marginTop: 20,
                            textAlign: "left",
                            paddingLeft: "5px",
                            fontStyle: "normal",
                          }}
                        >
                         
                          {this.t("popup_content1")}
                      
                        </div>
                        <div
                          style={{
                            width: "100%",
                            marginTop: 30,
                            textAlign: "left",
                            paddingLeft: "5px",
                          }}
                        >
                          <span
                            style={{
                              color: "#0F0E0E",
                              fontSize: 36,
                              fontFamily: "Inter",
                              fontWeight: "500",
                              textAlign: "left",
                              lineHeight: 1,
                              wordWrap: "break-word",
                              fontStyle: "normal",
                            }}
                          >
                            {/* Remember that this report can also be personalized
                                in a{" "} */}
                            {this.t("popup_content2")}{" "}
                          </span>
                          <span
                            style={{
                              color: "#DA291C",
                              fontSize: 36,
                              fontFamily: "Inter",
                              fontWeight: "500",
                              textAlign: "left",
                              wordWrap: "break-word",
                              lineHeight: 1,
                            }}
                          >
                            {/* virtual session at no cost */}
                            {this.t("popup_content3")}
                          </span>
                          <span
                            style={{
                              color: "#0F0E0E",
                              fontSize: 36,
                              fontFamily: "Inter",
                              fontWeight: "500",
                              textAlign: "left",
                              lineHeight: 1,
                              wordWrap: "break-word",
                            }}
                          >
                            {" "}
                            {/* with a Fortinet expert */}
                            {this.t("popup_content4")}
                          </span>
                        </div>
                          <div
                              style={{
                                width: "100%",
                                color: "#0F0E0E",
                                fontSize: 20,
                                fontFamily: "Inter",
                                fontWeight: "400",
                                textAlign: "left",
                                wordWrap: "break-word",
                                marginTop: 20,
                                paddingLeft: "5px",
                                fontStyle: "normal",
                              }}
                            >
                              
                              {this.t("popup_content5")}
                            </div>
                            <div
                              style={{
                                width: "95%",
                                height: "13%",
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 12,
                                paddingBottom: 12,
                                background: "#F3F3F3",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                // alignItems: "flex-start",
                                gap: 12,
                                display: "inline-flex",
                                marginTop: 20,
                                marginLeft: 5,
                                marginRight: 5,
                              }}
                            >
                              <div
                                style={{
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  gap: 8,
                                  display: "inline-flex",
                                }}
                              >
                                <input
                                  style={{
                                    color: "#0F0E0E",
                                    fontSize: 16,
                                    fontFamily: "Inter",
                                    fontWeight: "400",
                                    lineHeight: 1,
                                    background: "#F3F3F3",
                                    wordWrap: "break-word",
                                    borderColor: "#F3F3F3",
                                    paddingLeft: 5,
                                    width: "100%",
                                    height: "13%",
                                    border: "none",
                                  }}
                                  type="email"
                                  value={this.state.companyEmail}
                                  onChange={(e) => this.onEmailChange(e)}
                                  placeholder={this.t("popup_content6")}
                                />
                              </div>
                            </div>
                            <div
                              id="email_message"
                              className="LV_validation_message LV_valid"
                            >
                              <span id="email_message_span"></span>
                            </div>
                          
                      
                        <div
                          className="confirmButton"
                          style={{
                            marginTop: 20,
                            marginLeft: 5,
                            marginRight: 5,
                            
                          }}
                          onClick={() => this.props.closeModal()}
                        >
                          <div
                            style={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: 8,
                              display: "inline-flex",
                            }}
                          >
                           
                              
                          <div
                              style={{
                              color: "white",
                              fontSize: 16,
                              fontFamily: "Inter",
                              fontWeight: "600",
                              lineHeight: 1,
                              wordWrap: "break-word",
                              fontStyle: "normal",
                              }}
                          >
                              {/* Confirm */}
                              {this.t("popup_content7")}
                          </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          )
      
        
    }
}

export default ModalEmail